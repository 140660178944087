@import "src/global";

.account-info-cmp {
  & > * {
    width: calc(50% - #{$card-margin} * 2);
  }

  h4 {
    font-weight: 700;
  }

  .details-info-card-cmp {
    &-avatar,
    .skeleton-avatar {
      margin-right: 3em;
    }
    &-info-inner {
      display: flex;
    }

    .details-info-pairs-cmp li {
      margin-bottom: 8px;
    }
  }

  .profile-statistic {
    h3 {
      margin: 15px 0 0;
    }
    .points {
      font-weight: 700;
      margin: 0;
    }
    button {
      padding-left: 0;
      text-transform: uppercase;
    }
  }

  @media (max-width: 1200px) {
    .details-info-card-cmp {
      &-info-inner {
        flex-direction: column;
      }
    }

  }

  @media (max-width: 767px) {
    flex-direction: column;
    & > * {
      width: calc(100% - #{$card-margin} * 2);
    }
    .details-info-card-cmp {
      order: 1;
    }
    .profile-statistic {
      order: 0;
    }
  }

}
