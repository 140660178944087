@import '../base/variables';

.ant-btn-link.inline-link {
    padding: 0;
    color: inherit;
    &.btn-link-primary {
        color: inherit;
    }
    span {
        margin: 0;
    }
    &:hover {
        opacity: .8;
    }
}
