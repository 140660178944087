@import '../../global';

.categories-page {
  .ant-empty {
    width: 100%;
  }
  .universal-table.card {
    background: none;
    box-shadow: none;
    margin: 0;
  }
  .universal-table-footer {
    margin: 10px 10px 0;
  }
}

.c-prompt.category-editor-modal {
  width: 100%;
  .ant-modal-header {
    border-bottom: 2px solid $primary-color;
  }
}
