@import 'src/global';

.survey-details-page {
  &-main > div {
    width: calc(50% - #{$card-margin} * 2);
    align-self: flex-start;
  }

  // INFO COMPONENT
  &-details-list {
    @extend %listreset;
    margin-bottom: 2em;

    li {
      display: flex;
      margin-bottom: 1em;
      font-weight: 600;

      .label {
        min-width: 100px;
        max-width: 100px;
        margin-right: 1em;
        color: $hint-color;
      }
    }
  }

  &-counters {
    & div:first-child {
      margin-right: 5em;
    }

    .statistic-counter {
      span {
        font-size: 30px;
      }
    }
  }

  // QUESTIONS PREVIEW COMPONENT
  &-questions-preview-cmp {
    &-list {
      padding-left: 1em;

      & > li {
        padding-left: 1em;
        margin-bottom: 1em;

        h3 {
          font-weight: 700;
          line-height: 1;
          margin: 0;

          & + .hint {
            margin: 0.5em 0;
          }
        }

        ul {
          @extend %listreset;

          li {
            position: relative;
            padding-left: 1.5em;

            &:before {
              content: ' ';
              display: inline-block;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 5px;
              height: 5px;
              background: $success-color;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  // QUESTIONS STATISTIC COMPONENT
  &-questions-statistic-cmp {
    &-list {
      padding-left: 1em;

      & > li {
        padding-left: 1em;
        margin-bottom: 1em;

        h3 {
          font-weight: 700;
          line-height: 1;
          margin: 0;

          & + .hint {
            margin: 0.5em 0;
          }
        }

        ul {
          @extend %listreset;

          li {
            position: relative;
            padding-left: 0;

            &.def-statistic-item {
              p {
                padding-left: 6em;

                span:first-child {
                  position: absolute;
                  left: 0;
                }
              }
            }

            p {
              margin: 0 0 3px 0;
            }

            h5 + div > div {
              width: calc(25% - 25px);

              &:not(:last-child) {
                margin-right: 10px;
              }
            }

            .progress-bar {
              width: 100%;
              height: 6px;
              position: relative;
              background-color: #efefef;
              border-radius: 4px;
              margin-bottom: 10px;

              .progress-fill {
                position: absolute;
                width: 0;
                left: 0;
                top: 0;
                bottom: 0;
                border-radius: 4px;
                transition: width 1s;
              }
            }
          }
        }
      }
    }
  }

  &-qualified-info-text {
    display: block;
    margin: 4px 0 2px;
    font-size: 1rem;
    color: $red;
  }

  @media (max-width: 767px) {
    &-main  {
      flex-direction: column;
      & > div {
        width: calc(100% - #{$card-margin} * 2);
        align-self: flex-start;
      }
    }
  }
}

.card-rejected {
  &__title {
    display: block;
    padding: 0 0 15px;
    color: $red;
  }

  &__text {
    display: block;
    font-weight: 400;
  }
}
