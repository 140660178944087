// ant design
@import './vendors/antd-variables';
@import './vendors/antd-icons';
@import './vendors/apexcharts';

// base
@import './base/fonts';
@import './base/helpers';
@import './base/variables';
@import './base/hacks';
@import './base/theme';
@import './base/common';

// modules
@import './modules/copmonents';
@import './modules/forms';
@import './modules/button';

html {
  font-size: 12px;

  body {
    background: $body-back-color;
    font-family: $font-family-base;
    font-weight: 800;
    color: $text-color;
    font-size: 1rem;
    min-width: 400px;

    h1,
    h2,
    h3 {
      color: $text-color;
    }

    a {
      color: $link-color;
      font-weight: 500;

      &:hover {
        color: $primary-color;
      }
    }

    b {
      color: $text-color;
      font-weight: 800;
    }
  }
}
