@mixin createIcon($name, $url, $w, $h) {
  .anticon.anticon-#{$name} {
    width: $w;
    height: $h;
    background: url($url) no-repeat center center;
    background-size: $w, $h;
  }
}

@mixin addHoverIcon($name, $url, $w, $h) {
  button:hover .anticon.anticon-#{$name} {
    width: $w;
    height: $h;
    background: url($url) no-repeat center center;
    background-size: $w, $h;
  }
}

@include createIcon(c-user, '../../assets/images/svg/user.svg', 10px, 12px);
@include createIcon(c-error, '../../assets/images/svg/error.svg', 14px, 14px);
@include createIcon(c-lock, '../../assets/images/svg/lock.svg', 10px, 12px);
@include createIcon(c-logout, '../../assets/images/svg/logout.svg', 14px, 12px);
@include addHoverIcon(c-logout, '../../assets/images/svg/logout_hover.svg', 14px, 12px);
