@import '../../../../global';

.category-card {
  width: calc(33.333% - #{$card-margin} * 2);
  min-height: 165px;

  &-img {
    align-self: center;
    margin-right: 5%;
    max-width: 140px;
  }

  &-icon {
    align-self: center;
    height: max-content;
    max-width: 45px;
    max-height: 45px;
    border-radius: 50%;
  }

  h3 {
    font-weight: 700;
    margin: 0;
  }

  .category-card-buttons {
    margin-right: -$card-padding;

    button {
      padding: 0 10px;

      &:last-child {
        padding-right: $card-padding;
      }
    }
  }

  .label {
    margin-right: 10px;
  }

  @include media('<widescreen') {
    width: calc(50% - #{$card-margin} * 2);
  }

  @include media('<desktop') {
    width: 100%;
  }
}
