@import "src/global";

.buzz-edit-page {

}


.buzz-edit-form {

  &-inputs {
    flex: 1 1 auto;
  }

  &-description {
    flex: 5 1 auto;
    .form-group {
      height: 100%;
      margin: 0;
      padding: $form-group-margin 0 ($form-group-margin * 2) $form-group-margin;

      .form-control-wrapper {
        height: 100%;
        padding-bottom: 3px;

        textarea {
          height: 100%;
        }
      }
    }
  }

  &-upload-file.form-group {
    margin-top: 0;


  }

  @include b(mobile) {
    & > .flex {
      flex-direction: column;
    }
    &-description {
      .form-group {
        padding: 0;
      }
    }
  }

}
