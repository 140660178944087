@import '../../global';

$logo-block-height: 80px;

.ant-layout.main-wrapper {
  height: 100vh;
  min-height: 900px;

  .ant-layout-sider {
    background-image: linear-gradient(
      180deg,
      $primary-color-hover 0%,
      $primary-color 100%
    );

    .copyrights {
      position: absolute;
      bottom: 1em;
      left: 24px;
      text-align: center;
      font-size: 0.84rem;
      opacity: 0.6;
      color: #fff;
      font-weight: 500;
      white-space: nowrap;
    }

    &-collapsed {
      .copyrights {
        display: none;
      }
    }
  }

  // LOGO
  .nav-logo-block {
    padding: 0 12px;
    height: $logo-block-height;
    overflow: hidden;
    background: $white;

    .nav-logo {
      margin-right: 20px;
    }

    .logo-title {
      font-size: 2rem;
      margin: 0;
    }
    @include b(tablet) {
      padding: 0 3px;
    }
  }

  // MENU
  .ant-menu.ant-menu-root {
    padding-top: 40px;
    background: transparent;
    border-right: unset;

    .ant-menu-item {
      display: flex;
      align-items: center;
      height: 60px;
      margin: 0;
      //font-size: 1.17rem;
      //font-weight: 500;
      color: #a7e0db;

      a {
        color: #a7e0db;
      }

      &:hover,
      a:hover {
        color: $white;
      }

      &.ant-menu-item-selected {
        color: $white;
        background-color: rgba(0, 0, 0, 0.04);
        a {
          color: $white;
        }

        &:after {
          left: 0;
          border-right: none;
          border-left: 4px solid #99cc00;
          transform: scaleY(1);
          opacity: 1;
        }
      }
    }

    .nav-separator {
      width: 280px;
      padding-left: $wrapper-padding - $card-margin;
      line-height: 3;
      color: $white;
      font-weight: 800;
      text-transform: uppercase;
      overflow: hidden;
      opacity: 0.8;
    }

    // menu state
    &.ant-menu-inline-collapsed {
      .nav-separator {
        opacity: 0;
      }
      @include b(tablet) {
        width: 60px;
        .nav-separator { 
          display: none;
        }
        .ant-menu-item {
          padding: 0 20px;
        }
      }
    }
  }

  // HEADER
  .ant-layout-header {
    height: $logo-block-height;
    padding: 0 $wrapper-padding;
    background: transparent;

    .logout-btn {
      padding-right: 0;
      margin-left: 1em;
      color: $link-color;
    }
  }

  // CONTENT
  .ant-layout-content {
    padding: 0 ($wrapper-padding - $card-margin) $wrapper-padding;
    margin-top: -$card-margin;
    background: transparent;
    border-radius: $border-radius;

    & > *:last-child {
      margin-bottom: $wrapper-padding;
    }
    @include b(500) {
      padding: 0 5px $wrapper-padding;
    }
  }
}

.trigger.anticon {
  font-size: 18px;
  line-height: 64px;
  padding: 0 $wrapper-padding;
  margin-left: -$wrapper-padding;
  cursor: pointer;
  transition: color 0.3s;
  color: $link-color;

  &:hover {
    color: $primary-color;
  }
}

// Adaptive styles
.ant-layout {
  transition: all 0.2s;
}

@include b(tablet) {
  .main-wrapper {
    transition: all 0.2s;
    overflow: hidden;
    &.is-open-aside {
      .ant-layout {
        transition: all 0.2s;
        margin-right: -280px;
      }
    }
    .ant-layout-header {
      justify-content: flex-end;
    }
  }
}
