@import "../../../../global";

// !!!Column height should be static, because of charts calculating!
$col-height: 250px;

.column-chart-cmp {
  .stat-block-title + div {
    margin-bottom: 1em;

    & > :first-child {
      margin-right: 3em;
    }
  }

  .column-chart-dividers {
    height: 100%;
    min-height: $col-height;
    color: $hint-color;
  }

  .column-chart-block {
    padding: 0 1em;

    .column-chart-item {
      position: relative;
      width: 100%;
      min-width: 50px;
      overflow: hidden;
      padding: 0 .5em;

      &:not(:last-child) {
        //padding-right: 1em;

        //margin-right: 20%;
      }

      .column-chart-col {
        background: $back-color-hover;
        width: 12px;
        min-height: $col-height;
        border-radius: 2px;
        overflow: hidden;

        span {
          height: 0;
          width: 12px;
          align-self: flex-end;
          border-radius: 2px;
          overflow: hidden;
          transition: height 1s;
        }

        &:nth-child(1) {
          margin-right: 6px;

          span {
            background: $success-color;
          }
        }

        &:nth-child(2) span {
          background: $primary-color;
        }
      }

      .label {
        max-width: 100%;
        height: 4em;
        margin-top: 1em;
        line-height: 1.1;
      }

      // States
      &:hover .ellipsis {
        //position: relative;
        white-space: pre-wrap;
        background: $white;
        z-index: 1;
      }
    }
  }


  &-others {
    h3 {
      margin: 0;
    }
    .column-chart-other {
      margin-top: .5em;
      max-width: 60px;
      position: relative;
      cursor: default;

      .label {
        line-height: 1.3;
        margin: 0;
      }

      &:not(:last-child) {
        margin-right: 1.5em;
      }

      .column-chart-other-counters {
        .label {
          line-height: 1;
          margin: 0 2px;
        }
      }

    }
  }

  .label {
    cursor: default;
    font-size: 10px;
  }
}


