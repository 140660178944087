@import "src/global";

.buzz-details-page {
  &-img {
    width: 100%;
    max-width: 430px;
    height: auto;
    margin-bottom: 3em;

    &:hover {
      cursor: pointer;
    }
  }

  &-labels {
    margin-right: 1em;
  }

  &-description {
    font-weight: 500;
  }
}
