@import 'src/global';

$modal-padding: 20px;

.ant-modal {
  width: 100% !important;

  .ant-modal-content {
    border-radius: $border-radius;
    min-width: 400px;
  }

  // PROMPT MODAL
  &.c-prompt {
    // HEADER
    .ant-modal-header {
      padding: $modal-padding;
      border-bottom: unset;

      .ant-modal-title {
        font-size: 1.83rem;
        font-weight: 700;
        color: $text-color;
      }

      .ant-modal-close-x {
        //color: $hint-color;
      }
    }

    // BODY
    .ant-modal-body {
      padding: 0 $modal-padding $modal-padding $modal-padding;
      font-size: 1rem;
      color: $text-color;
      font-weight: 600;
    }

    .ant-modal-footer {
      padding: $modal-padding;
    }
  }

  // MEDIA MODAL
  &.c-media {
    .ant-modal-body img {
      border-radius: $border-radius;
      overflow: hidden;
    }
  }

  @include b(mobile) {
    max-width: 90%!important;
    .ant-modal-content {
      min-width: auto;
    }
  }

}
