@import '../../global';

.account-page-root {
  // PROFILE
  .account-profile {
    & > * {
      width: calc(50% - #{$card-margin} * 2);
    }

    h4 {
      font-weight: 700;
    }

    .profile-statistic {
      h3 {
        margin: 15px 0 0;
      }
      .points {
        font-weight: 700;
        margin: 0;
      }
      button {
        padding-left: 0;
        text-transform: uppercase;
      }
    }

    @include b(mobile) {
      flex-direction: column-reverse;
      & > * {
        width: calc(100% - #{$card-margin} * 2);
      }
    }
  }

  // CHANGE PASSWORD
  .change-pass-form {
    .form-group {
      max-width: 320px;
      margin-left: 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.change-phone-text {
  text-align: center;
  color: #777777;
  margin: 1em 0 3em;
}
