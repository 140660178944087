@import '../../global';

.card {
  padding: $card-padding;
  margin: $card-margin;
  border-radius: $border-radius;
  background-color: $white;
  box-shadow: $card-box-shadow;

  @include media('<widescreen') {
    //padding: calc(#{$card-padding} / 1.5);
  }
  @include b(500) {
    padding: $card-padding 15px;
  }
}

.search-block {
  margin: $card-margin;

  .search {
    max-width: 400px;
    margin-right: $card-margin;
  }

  input {
    padding-left: 40px !important;
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid #e6e8eb;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    .search {
      max-width: none;
      margin: 0 0 10px;
    }
  }
}

.statistic-counter {
  color: $text-color;
  margin: 0;
  font-size: 60px;
  line-height: 0.9;
  @include b(1200) {
    font-size: 46px;
  }
}

.statistic-title {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 1em;
}
