@import "src/global";

.skeleton {
  width: 100%;

  &-string {
    display: inline-block;
    width: 30%;
    //height: 1rem;
  }

  &-title {
    width: 40%;
  }

  &-paragraph {
    //width: 10%;
    &:last-child {
      width: 60%;
      margin-bottom: 0;
    }
  }

  &-img {
    height: 100px;
  }

  &-avatar {
    height: 100px;
    border-radius: 50%;
  }

  &-button {
    height: 40px;
    width: 140px;
  }

  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
