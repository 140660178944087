@import 'src/global';

.details-info-pairs-cmp {
  @extend %listreset;

  &:not(:last-child) {
    margin-bottom: 2em;
  }

  li {
    display: flex;
    margin-bottom: 1em;
    font-weight: 600;
    word-break: break-all;

    .label {
      min-width: 100px;
      max-width: 100px;
      margin-right: 1em;
      color: $hint-color;
      word-break: break-word;

      & + .btn-link {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
