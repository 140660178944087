@import "src/global";

.anticon.c-icon {
  width: 1rem;

  svg {
    width: inherit;
  }
}

@mixin addIconStyle($name, $color) {
  .anticon.c-icon-#{$name} {
    color: $color;
  }
}

@include addIconStyle('primary', $primary-color);
@include addIconStyle('error', $error-color);
@include addIconStyle('success', $success-color);
@include addIconStyle('warning', $warning-color);
@include addIconStyle('hint', $hint-color);
@include addIconStyle('label', $label-color);
