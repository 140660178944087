@import 'src/global';

.details-info-card-cmp {
  &-avatar {
    position: relative;
    display: block;
    width: 100%;
    height: 50vh;
    max-width: 120px;
    max-height: 120px;
    border-radius: 50%;
    margin-bottom: 1em;
    cursor: pointer;

    &:after {
      content: 'broken image';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: $hint-color;
      font-size: 0.8rem;
      text-align: center;
      z-index: 2;
      background: $white url(../../../../assets/images/svg/community_card.svg)
        center no-repeat;
      background-size: 90px;
    }
  }

  &-counters {
    & div:not(:last-child) {
      margin-right: 5em;
    }

    .statistic-counter {
      span {
        font-size: 30px;
      }
    }
  }

  .skeleton-avatar {
    margin-bottom: 1em;
  }

  &-info-block {
    .f-s-sb {
      @media (max-width: 767px) {
        flex-direction: column;
        padding-bottom: 20px;
      }
    }
  }
}
