@import '../../../../global';

.category-header-cmp {
  &-back-btn.btn-link {
    padding-left: 0;
  }

  &-control-btns-block > :not(:last-child) {
    margin-right: 10px;
  }

  &-title {
    font-weight: 800;
    font-size: 1.85rem;
    margin: 5px 0;
    line-height: 1;
  }

  &-subtitle {
    font-weight: 600;
    margin: 0;
    color: $label-color;
  }

  &-status {
    margin-top: 0.5em;

    &-color {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 0.5em;
      border-radius: 50%;

      & + span {
        font-weight: 600;
      }
    }
  }

  &-tabs {
    margin-bottom: -$card-padding;
    overflow: auto;
    scroll-behavior: smooth;

    .category-tab {
      position: relative;
      margin: 1em 20px 0 0;
      padding-bottom: $card-padding / 2;
      color: $link-color;
      font-weight: 600;
      white-space: nowrap;

      &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        margin: auto;
        width: 0;
        max-width: 100%;
        height: 4px;
        background: $success-color;
        transition: width 0.2s;
      }

      // States
      &.active {
        color: $text-color;

        &:after {
          width: 200px;
          transition: 0.8s;
        }
      }

      &:hover {
        cursor: pointer;
        color: $success-color;
      }
    }
  }

  @media (max-width: 1000px) {
    & > .f-c-sb {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-control-btns-block {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 10px 0 15px;
      button {
        margin: 0 0 5px;
        width: 100%;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    &-tabs {
      flex-direction: column;
      padding-top: 20px;
      .category-tab {
        margin: 0;
        width: 100%;
        text-align: center;
        margin: 0 0 20px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
