@import '../../../../global';

$table-padding: 0.9em;

.ant-layout .universal-table {
  padding: 0;

  .universal-table-wrapper {
    overflow: auto;

    table {
      //table-layout: fixed;
      font-weight: 500;
      width: 100%;
      min-width: 900px;

      &.mobile-table {
        min-width: auto;
      }

      &.fixed-column {
        @for $i from 1 through 20 {
          &--#{$i} {
            td,
            th {
              width: calc(100% / #{$i});
            }
          }
        }
      }

      // HEADER
      thead th {
        position: relative;
        width: min-content;
        padding: 1.5em 1.9em 1em 0.8em;
        font-weight: 500;
        font-size: 1.17rem;
        border-bottom: 2px solid $primary-color;

        &:not(:last-child) {
          border-right: 1px solid $back-color-hover;
        }

        button {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          padding-right: 0;
        }

        &.id {
          width: 5%;
          min-width: 70px;
        }

        &.title {
          width: 15%;
          min-width: 170px;
        }

        &.dateCreated,
        &.creationDate {
          width: 10%;
          min-width: 97px;
        }

        &.webURL {
          width: 10%;
        }

        &.email {
          width: 350px;
        }

        &.action,
        &.userId {
          width: 15%;
          min-width: 120px;
        }

        &.passedTimes,
        &.status,
        &.importantPercent {
          width: 11%;
        }

        &.extraPoints,
        &.points {
          width: 8%;
        }

        &.isActive,
        &.deleted,
        &.blocked {
          width: 6%;
        }
      }

      // BODY
      tbody tr {
        &:nth-child(odd) {
          background: $back-color;
        }

        td {
          padding: $table-padding;
          position: relative;

          &.cell-loading:after {
            content: ' ';
            display: inline-block;
            position: absolute;
            top: $table-padding;
            left: $table-padding;
            width: calc(100% - 1.8em);
            height: calc(100% - 1.8em);
            background: linear-gradient(
              90deg,
              #f2f2f2 25%,
              #e6e6e6 37%,
              #f2f2f2 63%
            );
            background-size: 400% 100%;
            animation: skeleton-loading 1.4s ease infinite;
          }

          // ellipsis for cells
          &.cell-ellipsis {
            position: relative;

            &:before {
              content: '&nbsp;';
              visibility: hidden;
            }

            .ant-checkbox-wrapper {
              position: absolute;
              width: 16px;
              height: 16px;
              top: 0;
              left: $table-padding;
            }

            span:not(.ant-checkbox):not(.c-icon) {
              position: absolute;
              left: $table-padding;
              right: $table-padding;
              top: $table-padding;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            button {
              position: absolute;
              top: $table-padding / 2;
              left: $table-padding;
              right: $table-padding;
              width: calc(100% - #{$table-padding} * 2);
              text-transform: unset;
              height: 25px;

              span:not(.ant-checkbox):not(.c-icon) {
                top: 2px;
                display: block;
              }
            }

            .c-icon {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              height: 10px;
            }
            @include b(500) {
              .mobile-table__value {
                white-space: nowrap;
                padding-left: 20px;
                text-align: right;
                word-break: break-all;
              }
              span:not(.ant-checkbox):not(.c-icon) {
                white-space: pre-wrap;
                &.mobile-table__title {
                  white-space: pre-wrap;
                  padding: 0;
                  overflow: visible;
                  text-overflow: unset;
                }
              }
            }
          }

          &:not(:last-child) {
            border-right: 1px solid $back-color-hover;
          }
        }

        &.hoverable:hover {
          background: $back-color-hover;
          cursor: pointer;
        }
      }

      &.mobile-table {
        td {
          display: block;
        }
      }
    }
  }

  // FOOTER
  .universal-table-footer {
    padding: $table-padding;
    background: $primary-color;
    font-weight: 500;
    color: $white;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .ant-input-number {
      width: 50px;
      height: 25px;
      margin: 0 0.8em;
      border-radius: $border-radius;

      .ant-input-number-handler-wrap {
        width: 15px;
        opacity: 1;
        border-left: 0;

        span {
          border: 0;
        }
      }

      .ant-input-number-input {
        height: 25px;
        width: 45px;
        padding-left: 5px;
        text-align: center;
      }
    }

    .universal-table-limit-select {
      margin: 0 5px;
    }

    .universal-table-pagination {
      span {
        margin: 0 $table-padding;
      }
    }
    @at-root .universal-table-page-select {
      width: fit-content !important;
    }

    .ant-select {
      .ant-select-selector {
        width: 40px;
        height: 25px;
        margin: 0;
        padding: 0;
      }
      &-selection {
        &-search {
          display: none;
        }
        &-item {
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-arrow {
        display: none;
      }
    }

    .ant-btn-circle {
      min-width: 25px;
      height: 25px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin: 0;
      }

      &:focus {
        border-color: $primary-color-hover;
      }

      &[disabled] {
        background-color: $primary-color-hover;
        border-color: $primary-color-hover;
      }

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.drag-n-drop-table {
  &__row-drag {
    display: table;
  }
  tr {
    // background: #fff !important;
    // border: 0;
    height: 39px !important;
  }
  td {
    cursor: grab;
  }
}

@media (max-width: 1000px) {
  .ant-layout .ant-layout-content .universal-table {
    background: transparent;
    box-shadow: none;
    .universal-table-wrapper {
      overflow: visible;
      .mobile-table {
        tbody {
          tr {
            display: block;
            margin-bottom: 20px;
            box-shadow: 0 0 15px rgba(32, 36, 39, 0.05);
            td.cell-ellipsis {
              display: flex;
              border: 0;
              justify-content: space-between;
              border-bottom: 1px solid #ddd;
              background: $back-color;
              width: 100%;
              &:before {
                display: none;
              }
              .mobile-table__title {
                position: static;
                padding-right: 20px;
              }
              .mobile-table__value {
                span {
                  position: static;
                }
                button {
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }
  .drag-n-drop-table {
    tr {
      height: auto !important;
    }
  }
}

@media (max-width: 680px) {
  .universal-table-footer {
    flex-direction: column;
    .universal-table-pagination {
      margin-top: 20px;
    }
  }
}
