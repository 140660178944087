@import '../../../../global';

.ant-btn.ant-btn-default {
    background: $primary-color;
    color: $white;
    font-size: 1.1rem;
    font-weight: 800;

    &:not(.ant-btn-circle) {
      height: 40px;
      border-radius: 6px;
    }

    & + input[type=file]{
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }

    a {
      font-weight: 800;
    }

    // State
    &:hover, &:focus {
      background-color: $primary-color-hover;
      border-color: $primary-color-hover;
      color: $white;
    }

    &:after {
      box-shadow: 0 0 0 red !important;
    }

  &.btn-default {
    text-transform: uppercase;
  }
}

@mixin addButtonStyle($name, $bg-color) {
  .btn-default.btn-default-#{$name},
  .btn-file.btn-file-#{$name} {
    background: $bg-color;

    &:hover, &:hover:focus {
      background: darken($bg-color, 5.3);
      border-color: transparent;
      color: $white;
    }

    &:focus {
      background: $bg-color;
      border-color: transparent;
      color: $white;
    }
  }
}

.ant-btn-link.btn-link, .btn-link {
  font-size: 1rem;
  font-weight: 800;
  color: $label-color;

  span {
    margin-left: 8px;
  }

  &:focus {
    color: $label-color;
  }

  &:hover {
    color: $primary-color;
  }
}

@mixin addLinkBtnStyle($name, $color) {
  .ant-btn-link.btn-link.btn-link-#{$name}, .btn-link.btn-link-#{$name} {
    font-weight: 700;
    color: $color;

    &:hover, &:hover, &:hover:focus {
      font-weight: 700;
      color: darken($color, 20.4);
    }

    &:focus {
      color: $color;
    }

    &[disabled] {
      color: transparentize($color, .5);
    }
  }

  .btn-link.btn-link-primary {
    &:hover, &:hover:focus {
      color: darken($primary-color, 5.3);
    }
  }
}

@include addButtonStyle('primary', $primary-color);
@include addLinkBtnStyle('primary', $label-color);

@mixin combinedMaker($name, $color) {
  @include addButtonStyle($name, $color);
  @include addLinkBtnStyle($name, $color);
}


@include combinedMaker('error', $error-color);
@include combinedMaker('success', $success-color);
@include combinedMaker('warning', $warning-color);
@include combinedMaker('hint', $hint-color);
@include combinedMaker('label', $label-color);
