@import "src/global";

.community-edit-page {

}

.community-edit-form {
  & > .card {
    width: calc(50% - #{$card-margin} * 2);
    align-self: flex-start;
  }
  @include b(mobile) {
    & > .card {
      width: calc(100% - #{$card-margin} * 2);
    }
  }
}
