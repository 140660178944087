@import '../../../../global';
.circle-chart-cmp {
  margin-top: 1.5em;

  &-details {
    margin-left: 3em;
    width: calc(100% - 200px - 3em);
  }

  &-title {
    margin-bottom: 2em;
    line-height: 1;

    h3 {
      margin: 0;
      font-size: 3rem;
      font-weight: 600;
    }
  }

  &-legend {
    & > div {
      margin: 0 0 1em 0;

      &:not(:last-child) {
        margin: 0 1em 1em 0;
      }
    }

    &-values {
      font-weight: 600;
      line-height: 1;

      p {
        margin: 0;
      }
    }
  }

  .label {
    font-weight: 600;
  }

  &-chart {
    width: 200px;
  }
  @include b(500) {
    &-chart {
      width: 150px;
    }
    &-details {
      margin-left: 1em;
      width: calc(100% - 150px - 1em);
    }
  }
}
