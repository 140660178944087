// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);

$pallete: (
  line: #4888e5,
  acid-title: #4888e5
);

//----------------------------------------
//   Variables
//----------------------------------------

// 1 - Color
// 2 - Font
// 3 - Grid and wrapper
// 4 - Spacing
// 5 - Breakpoints
// 6 - Transitions

//
// 1 - Color
// ---------------------------------------

$clr-black: #000;
$clr-white: #fff;
$clr-1: #f8f8f8;
$clr-2: #4888e5;
$clr-3: #d0d0d0;
$clr-4: #6c6c6c;
$clr-5: #0073af;
$clr-6: #74ba2a;
$clr-7: #949494;
$clr-8: #bb357e;
$clr-9: #b8b8b8;
$clr-10: #1a6be1;
$clr-11: #8e8e8e;
$clr-12: #393a3b;
$clr-13: #75b842;
$clr-14: #2c8aac;
$clr-15: #868686;
$clr-17: #e1f6cb;
$clr-18: #d8f2ff;
$red: #e87171;
$white: #ffffff;

// Base
$primary-color: #129793;
$primary-color-hover: #25b6a9;
$error-color: #e87171;
$warning-color: #ecb75d;
$back-color: #f5f7f7;
$success-color: #99cc00;
// Base state
$back-color-hover: #e8e8e8;
$back-color-checked: $back-color;


// Elements
$body-back-color: $back-color;
$label-color: #777777;
$input-back-color: $back-color;
$border: 1px solid $back-color;
$checkbox-back-color: $back-color;
$link-color: #b0b0b0;
$hint-color: #b0b0b0;
$text-color: #555555;
$placeholder-color: transparentize($text-color, .5);

// Elements state
$border-hover: 1px solid $back-color-hover;
$input-border-focused: 1px solid $primary-color;
$select-dropdown-hover-back-color: $back-color;

//
// 2 - Elements
// ---------------------------------------
$wrapper-padding: 30px;
$card-margin: 10px;
$card-padding: 20px;
$card-box-shadow: 0 0 15px rgba(32, 36, 39, 0.05);
$border-radius: 6px;
$form-group-margin: 1.6em;

//
// 3 - Font
// ---------------------------------------

$font-family-base: Muli, GothamProRegular, sans-serif;
$font-family-bold: "Muli Extra Bold";


//
// 4 - Grid and wrapper
// ---------------------------------------

$page-min-width: 320px;
$page-max-width: 1210px;
$columns-gutter: 20px;

//
// 5 - Breakpoints
// ---------------------------------------

$breakpoints: (
  "laptop-lg": 1680px,
  "laptop": 1300px,
  "tablet": 1000px,
  "phone-lg": 600px,
  "phone": 480px
);

//Height Titles and margins
$title-top: 50px;
$title-bottom: 40px;
$title-height-sm: 50px;
$title-height-lg: 60px;
$title-height-dif-sm: 11px;
$title-height-dif-lg: 12px;
$header-title-height-sm: $title-height-sm + $title-top + $title-bottom;
$header-title-height-lg: $title-height-lg + $title-top + $title-bottom;
$tab-list-top: 30px;

//Size of sidebar's menu circle
$size-circle-lg: 1560px;
$size-circle-sm: 1200px;
