@import '../../global';

.dashboard-page {
  .total-card {
    width: calc(20% - #{$card-margin} * 2);
    color: $back-color-hover;

    .total-card-details {
      width: 100%;
      margin-left: 5%;

      h3 {
        color: $text-color;
        font-size: 60px;
        line-height: 0.9;
        margin: 0;
      }

      span {
        display: table-cell;
        height: 20px;
        vertical-align: middle;
        line-height: 1;
        color: $link-color;
      }
    }

    @include media('<xl-widescreen') {
      & {
        padding: calc(#{$card-padding} / 1.5) $card-padding;
      }

      svg {
        width: 50px;
      }
      .total-card-details h3 {
        font-size: 50px;
      }
    }

    @include media('<widescreen') {
      & {
        padding: calc(#{$card-padding} / 2);
      }

      svg {
        display: none;
      }
    }

    &:hover {
      cursor: pointer;
      //background: $back-color-hover;
      opacity: 0.8;
      box-shadow: unset;
    }
  }

  .stat-block {
    width: calc(40% - #{$card-margin} * 2);
    //max-height: 230px;

    & + .stat-block {
      width: calc(60% - #{$card-margin} * 2);
    }

    &-title {
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 0;
    }

    .legend-color {
      display: inline-block;
      min-width: 16px;
      min-height: 16px;
      margin-right: 0.5em;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  @include b(1500) {
    .f-str-c {
      flex-wrap: wrap;
    }
    .total-card {
      width: calc(33.33% - #{$card-margin} * 2);
    }
    .stat-block {
      width: 100%;
      & + .stat-block {
        width: 100%;
      }
    }
    .total-card {
      width: calc(50% - #{$card-margin} * 2);
    }
  }
  @include b(1200) {
    .stat-block {
      & + .stat-block {
        flex-direction: column;
      }
    }
  }
}

.ant-layout-content {
  .dashboard-page--cut {
    flex-direction: row;
    flex-wrap: wrap;
    .category-header-cmp {
      width: 100%;
    }
    .f-str-c {
      width: 50%;
      &:nth-child(2) {
        width: 100%;
      }
    }
    .total-card {
      width: calc(33.33% - 10px * 2);
    }
    .stat-block {
      width: 100%;
      flex-direction: row;
      .w100 {
        width: 50%;
      }
    }
  }
}
