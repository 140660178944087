//@import '../../global';

.offensive-words-page {
  h3 {
    font-size: 1.33rem;
    font-weight: 700;
  }

  .hint {
    margin-top: 5px;
  }

  &__field {
    display: flex;
    .ant-btn {
      margin-left: 15px;
    }
  }

  @media (max-width: 767px) {
    &__field {
      flex-direction: column;
      .ant-btn {
        margin: 15px 0;
      }
    }
  }

}
