@import "variables";

@mixin makeColorsSet($name, $color) {
  .c-#{$name} {
    color: $color;
  }
  .bg-#{$name} {
    background: $color;
  }
}

@include makeColorsSet('primary', $primary-color);
@include makeColorsSet('success', $success-color);
@include makeColorsSet('error', $error-color);
@include makeColorsSet('warning', $warning-color);
@include makeColorsSet('hint', $hint-color);

