@import '../../global';

.points-edit-form {
  .ant-collapse-content-box > div {
    max-width: 1400px;
  }

  &-list-wrapper {
    width: 100%;
    max-width: 530px;
    font-size: 1rem;

    &:first-child {
      margin-right: 20px;
    }

    ul {
      @extend %listreset;

      .c-icon {
        margin-left: 6px;
      }

      .form-group {
        margin: 5px 20px 5px 40px;
      }

      .points-regular-field {
        padding-right: 34px;
      }
    }
  }

  @include b(1400) {
    &-list-wrapper {
      ul {
        .form-group {
          margin: 5px 20px 5px 0;
        }
      }
      li {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  @include b(mobile) {
    .ant-collapse-content-box > div {
      flex-direction: column;
    }
    &-list-wrapper {
      &:first-child {
        margin: 0 0 20px;
      }
    }
  }
}
