
.flex {
  display: flex;
}

.f-column {
  @extend .flex;
  flex-direction: column;
}

.f-wrap {
  @extend .flex;
  flex-wrap: wrap;
}

.f-c-c {
  @extend .flex;
  align-items: center;
  justify-content: center;
}

.f-c-sb {
  @extend .f-c-c;
  justify-content: space-between;
}

.f-c-e {
  @extend .f-c-c;
  justify-content: flex-end;
}

.f-c-s {
  @extend .f-c-c;
  justify-content: start;
}

.f-s-c {
  @extend .f-c-c;
  align-items: start;
}

.f-s-str {
  @extend .flex;
  align-content: stretch;
}

.f-e-s {
  @extend .flex;
  align-items: flex-end;
}

.f-e-c {
  @extend .f-e-s;
  justify-content: center;
}

.f-e-sb {
  @extend .f-e-s;
  justify-content: space-between;
}

.f-str-c {
  @extend .f-c-c;
  align-items: stretch;
}

.f-s-sb {
  @extend .flex;
  justify-content: space-between;
}

.f-str-s {
  @extend .flex;
  align-items: stretch;
}

.f-sb-s {
  @extend .f-column;
  @extend .f-s-sb;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.h100 {
  height: 100%;
}

.invisible {
  opacity: 0;
}

.rounded {
  border-radius: $border-radius;
  overflow: hidden;
}

// TEXT
// --------------------------
.highlight {
  color: $primary-color;
}

.label {
  font-weight: 500;
  color: $label-color;
}

.hint {
  font-weight: 600;
  color: $hint-color;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.capitalize {
  text-transform: capitalize;
}
