@import '../../../global';

.survey-edit-form {
  & > .card {
    width: calc(50% - #{$card-margin} * 2);
    align-self: flex-start;
  }

  .card {
    //flex: 1 1 auto;
    .form-group:first-child {
      margin-top: 0;
    }
  }

  .ant-checkbox + span {
    font-weight: 700;
    color: $text-color;
  }

  // RIGHT SIDE BLOCK
  &-right-side {
    //align-self: baseline;

    ol {
      @extend %listreset;

      & + .btn-link {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.1rem;
        color: $text-color;
      }

      // QUESTION BLOCK
      & > li {
        margin: 0 -#{$card-padding};
        padding: 0 $card-padding 1em;
        margin-bottom: 1.5em;
        border-bottom: 2px solid $primary-color;

        h3 {
          font-weight: 700;
          .btn-link {
            padding-right: 0;
          }
        }

        ul {
          @extend %listreset;

          // Question OPTION block
          li {
            .form-group {
              width: 100%;
              margin: 5px 0;
            }

            .btn-link {
              padding-right: 0;
              margin: 0 0 0 -5px;

              svg {
                width: 10px;
              }
            }
          }

          & + .btn-link {
            padding-left: 0;
            text-transform: uppercase;
            font-weight: 700;
            margin-top: 5px;
          }
        }
      }
    }

    .form-group--counter {
      & + .ant-btn {
        align-self: flex-start;
        margin-top: 7px;
      }
    }
    .qualified-checkbox {
      align-self: flex-start;
      width: 30px;
      text-align: center;
      margin-top: 14px;
    }
  }

  &--pulse {
    .survey-edit-form-right-side {
      ol {
        & > li {
          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    .card {
      & > .form-group {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @include b(mobile) {
    flex-wrap: wrap;
    & > .card {
      width: calc(100% - #{$card-margin} * 2);
    }
  }
}
