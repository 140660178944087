@import '~antd/dist/antd.css';
@import '../base/variables';

// BUTTONS
// ---------------------------------------
// !using universal buttons

// TOOLTIP
// ---------------------------------------
// !using universal tooltip

// INPUT
// ---------------------------------------
.ant-input,
.select-input .ant-select-selection,
.ant-picker {
  height: 40px;
  padding: 10px 11px 8px;
  background: $input-back-color;
  border: $border;
  border-radius: $border-radius;
  display: block;

  &-focused {
    border: $input-border-focused;
    box-shadow: unset;
  }
  &:hover {
    border: $border-hover;
    box-shadow: unset;
  }

  &:focus {
    border: $input-border-focused;
    box-shadow: unset;
  }
}

.password-field {
  display: block;
  position: relative;
  .anticon.anticon-c-error {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    right: 40px;
  }
  input[type='password'].ant-input {
    padding-right: 65px;
  }
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 13px 11px 8px;
  border: 1px solid transparent;
  &:focus {
    border: 1px solid #129793;
  }
}

.ant-input-affix-wrapper {
  background: transparent;
  border: 0 !important;
  padding: 0;
  margin: 0;
  &:before {
    display: none;
  }
  &:hover {
    border: 0 !important;
  }
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border: $border-hover;
  box-shadow: unset;

  &:focus {
    border: $input-border-focused;
  }
}

.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}

.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}

// SELECT
.select-input .ant-select-selection {
  .ant-select-selection__rendered {
    margin-right: 0;
    margin-left: 0;
    line-height: unset;
    font-weight: 500;
    color: $text-color;
  }

  &:focus {
    border: $border-hover;
    box-shadow: unset;
  }
}

.ant-select-item-option {
  padding: 5px 10px;
  &:hover:not(.ant-select-item-option-disabled),
  &-selected:not(.ant-select-item-option-disabled) {
    background: $select-dropdown-hover-back-color;
  }
}

// DATEPICKER
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border: $border-hover;
}

// CHECKBOX
// ---------------------------------------
.ant-checkbox-wrapper {
  // States
  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border: $border-hover;
    background: $back-color-hover;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: $border-hover;
    background: $back-color-checked;
  }

  .ant-checkbox-checked {
    &::after {
      border: $border-hover;
    }
    .ant-checkbox-inner {
      background: $back-color-checked;
      border: $border-hover;

      &::after {
        border-color: $primary-color;
      }
    }
  }
}

// TAGS
// ---------------------------------------
.ant-tag.c-tag {
  position: relative;
  padding-right: 30px;
  margin: 0 10px 10px 0;
  line-height: 2.4;
  background: $error-color;
  color: $white;
  border: 0;

  .anticon {
    position: absolute;
    top: 0;
    right: 5px;
    width: 20px;
    height: 100%;
    color: inherit;
    background: url('../../assets/images/svg/tag_remove.svg') no-repeat center;

    svg {
      display: none;
    }
  }

  &:hover {
    background: darken($error-color, 20.4);
  }
}

// ACCORDION
// ---------------------------------------
.ant-collapse.c-collapse {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: 0;

  .ant-collapse-item {
    margin: $card-margin;
    border-radius: $border-radius !important;
    overflow: hidden;
    background-color: $white;
    box-shadow: $card-box-shadow;

    .ant-collapse-header {
      padding: $card-padding;
      border-radius: 0 !important;

      .ant-collapse-arrow {
        color: $primary-color;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .ant-collapse-content {
      border-radius: 0 !important;
    }

    // States
    &-active {
      .ant-collapse-header {
        border-bottom: 2px solid $primary-color;

        .ant-collapse-arrow {
          color: $label-color;
        }
      }
    }
  }
}

.select-input {
  font-size: 14px;
  outline: none;
  width: 100%;
  &:focus {
    box-shadow: none;
  }

  .ant-select-selection-item {
    line-height: unset;
    font-weight: 400;
  }

  // START BUILD MODE
  & > div {
    &:first-child,
    &:nth-child(2) {
      // control
      height: 40px;
      padding: 10px 11px 8px;
      background: #f5f7f7;
      border: 1px solid #f5f7f7;
      border-radius: 6px;
      box-shadow: none;
      &:focus {
        border: 1px solid #e8e8e8;
        box-shadow: unset;
      }
      &:hover {
        border: 1px solid #e8e8e8;
        box-shadow: unset;
      }

      & > div {
        &:first-child {
          // ValueContainer
          padding: 0;
          margin-right: 0;
          margin-left: 0;
          line-height: unset;
          font-weight: 500;
          color: #555555;
        }
        & > div {
          &:last-child {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  // FINISH BUILD MODE

  &[class$='-container'] {
    outline: none;
    &:focus {
      box-shadow: none;
    }
  }

  [class$='-control'] {
    height: 40px;
    padding: 10px 11px 8px;
    background: #f5f7f7;
    border: 1px solid #f5f7f7;
    border-radius: 6px;
    box-shadow: none;
    &:focus {
      border: 1px solid #e8e8e8;
      box-shadow: unset;
    }
    &:hover {
      border: 1px solid #e8e8e8;
      box-shadow: unset;
    }
  }
  [class$='-ValueContainer'] {
    padding: 0;
    margin-right: 0;
    margin-left: 0;
    line-height: unset;
    font-weight: 500;
    color: #555555;
  }
  [class$='-placeholder'] {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: #555555;
  }
  [class$='-Input'] {
    margin: 0;
    padding: 0;
  }

  [class$='-menu'] {
    margin-top: 8px;
    & > div {
      & > div {
        font-size: 14px;
        line-height: 22px;
        padding: 5px 12px;
        color: rgba(0, 0, 0, 0.65);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 400;
        &:active {
          background: #fff;
        }
      }
    }
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  padding: 10px 11px 8px;
  background: #f5f7f7;
  border: 1px solid #f5f7f7;
  border-radius: 6px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid #e8e8e8;
  border-right-width: 1px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: unset;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #e8e8e8;
  box-shadow: unset;
}

.rc-virtual-list-scrollbar {
  width: 3px !important;
  &-thumb {
    background: map-get($pallete, line)!important;
  }
}
