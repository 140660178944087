@import '../../global';

.logo-title {
  margin: 0.5em auto 1em;
  font-family: 'Oswald', Helvetica, sans-serif;
  font-size: 2.7rem;
}

.auth-page {
  min-height: 100vh;
  padding: 20px 0;

  .card {
    width: 100%;
    padding: 30px;
    max-width: 400px;
    margin-bottom: 2em;

    h2 {
      text-align: center;
      font-weight: 800;
    }

    .login-form {
      h2 {
        margin-bottom: 1em;
      }

      .remember-me {
        margin: 0 0 2em 0;
        .form-group {
          margin: 0;
        }
      }
    }

    .forgot-pass-form {
      img {
        margin-bottom: 1em;
      }

      p {
        text-align: center;
        color: $label-color;
        margin-bottom: 3em;
        a {
          display: table;
          margin: 10px auto 0;
        }
      }
    }
  }
}

.go-back-link {
  font-weight: 600;
}
