@import '../../../../global';

@mixin addTooltipStyle($name, $bg-color) {
  .c-tooltip-#{$name} {
    .ant-tooltip-inner,
    .ant-tooltip-arrow-content {
      font-size: 1rem;
      background-color: $bg-color;
    }
  }
}

@include addTooltipStyle('primary', $primary-color);
@include addTooltipStyle('success', $success-color);
@include addTooltipStyle('error', $error-color);
