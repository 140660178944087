@import '../base/variables';

.form-group {
  margin: $form-group-margin auto;

  .form-control-wrapper {
    position: relative;

    // SWITCH
    &--switch {
      label {
        cursor: pointer;
      }
      .ant-switch {
        margin-right: 15px;
      }
    }

    // TEXT INPUT
    .text-input-label {
      display: inline-block;
      margin-bottom: 5px;
    }

    // TEXTAREA
    .icon-textarea {
      position: absolute;
      top: 13px;
      right: 11px;
      z-index: 1;
    }

    label + .icon-textarea {
      top: 33px;
    }

    // CHECKBOX
    .ant-checkbox-wrapper {
      color: $label-color;
      font-weight: 500;
      font-size: 1rem;
    }

    // SELECT
    .ant-select {
      .ant-select-selection__placeholder {
        display: block !important;
      }

      &.has-value .ant-select-selection__placeholder {
        display: none !important;
      }

      &-disabled {
        .ant-select-selection {
          border: $border-hover;
          background: $white;
        }
        .ant-select-selection__rendered,
        .ant-select-arrow {
          //color: red;
          color: transparentize($text-color, 0.6);
        }
      }
    }

    // DATEPICKER
    .ant-calendar-picker {
      display: block;
    }

    // UPLOAD FILE
    .upload-file-cmp {
      .preview-block {
        position: relative;
        cursor: pointer;

        img {
          width: auto;
          height: 60px;
        }

        .btn-link {
          position: absolute;
          right: -20px;
          top: -20px;
          width: auto;
          padding: 0 8px;

          svg {
            width: 10px;
          }
        }
      }

      .btn-default {
        width: auto;
      }

      &-picture {
        .preview-block {
          margin-top: 2em;
          padding: 8px;
          border: $border-hover;
          border-radius: $border-radius;
          display: table;
        }
      }

      &-avatar {
        .preview-block {
          margin-right: 2em;

          img {
            height: 90px;
            overflow: hidden;
            border-radius: 50%;
          }
        }

        .btn-link {
          display: none;
        }
      }

      &-file {
        margin-left: 1em;

        button svg {
          width: 8px;
        }
      }
    }

    // UPLOAD PDF FILE
    .form-control-download {
      .cropper-cmp {
        display: inline-block;
      }
      .universal-spinner {
        display: inline-block;
        margin-left: 1em;
      }
    }
  }
  .form-control-download {
    button {
      margin-right: 10px;
    }
  }

  &__counter {
    font-size: 0.8125rem;
    color: #8695a1;
    z-index: 10;
    display: block;
    text-align: right;
    position: static;
    display: table;
    margin: 5px 0 0 auto;
  }
  textarea.ant-input {
    margin: 0;
  }
}

.input {
  &--error {
    border-color: $red;
  }
}
